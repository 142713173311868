.app__header {
    font-family: 'Nunito';
    margin-top: 15px;
}

.app__header h1 {
    // border: 1px solid pink;
    font-family: 'Nunito';
    font-weight: 700;
}

.app__header h1::after {
    content: '';
    background: $burnt-sienna;
    display: block;
    height: 4px;
    left: 0px;
    margin-top: 8px;
    top: 32px;
    width: 80px;
}

.app__body {
    font-family: 'Nunito';
    margin-block-start: 10px;
    // padding-inline: 0px;
    h3 {
        font-weight: 500;
    }
}

// .app__body h3 {
//     border: 1px solid yellow;
// }


.app__col {
    text-align: center;
    padding-block: 15px;
}

.app__img {
    border-radius: 10px;
    border: 3px solid black;
    height: 100%;
    width: 100%;
}

.app__store {
    aspect-ratio: 40/12;
    border-radius: 15px;
    // height: 120px;
    max-width: 300px;
    object-fit: cover;
    overflow: hidden;
    width: 100%;
}

.app__footer {
    aspect-ratio: 40/12;
    border-radius: 15px;
    // height: 120px;
    max-width: 240px;
    object-fit: cover;
    overflow: hidden;
    width: 100%;
}

@media (min-width: 768px ) {
    .app__store {
        max-width: 400px
    }
}