.footer-body {
    background-color: $charcoal;
    color: lighten($color: $charcoal, $amount: 50);
    font-family: 'Nunito';
    // height:  ;

    // border: 1px solid yellow;
    // position: relative;
    // padding-inline-start: 100px;

    h5 {
        font-weight: 500;
        text-align: center;
    }

    a {
        color: lighten($color: $charcoal, $amount: 50);
    }

    a:hover {
        color: lighten($color: $charcoal, $amount: 70);
        // color: black;
    }
    
}

.footer-header {
    padding-block: 20px;
}

.footer-rrss-row {
    padding-block-end: 10px;
    justify-content: center;
    // text-align: center;
}

.footer-rrss-col {
    padding: 10px;
    text-align: center;
}

.footer__local-col {
    justify-content: center;
    width: 760px;

}

.footer__local {
    width: 100%;
    overflow-x: hidden;
    // max-width: 1000px;
    object-fit: cover;
    // display: flex;
    // justify-content: center;
}

.footer__copy {
    width: 100%;
    text-align: center;
}




// $footer-height: map-get($map: , $key: )