.diving__header {
    font-family: 'Nunito';
    margin-top: 15px;
}

.diving__header h1 {
    // border: 1px solid pink;
    font-family: 'Nunito';
    font-weight: 700;
}

.diving__header h1::after {
    content: '';
    background: $burnt-sienna;
    display: block;
    height: 4px;
    left: 0px;
    margin-top: 8px;
    top: 32px;
    width: 80px;
}

.diving__col {
    margin-block: 15px;
    font-family: 'Nunito';
    margin-top: 1rem;
    text-align: justify;
}

.diving__card {
    background-color: #f8f8f8;
}

.diving__card-footer {
    cursor:pointer;
}

.diving__modal-header {
    font-family: 'Nunito';
}

.diving__modal-body {
    font-family: 'Nunito';
    text-align: justify;
}

.diving__modal-body p {
    padding-block-start: 15px
}

.diving__loading {
    display: flex;
    // border: 1px solid blue;
    width: 100%;
    height: 400px;
    // align-content: center;
    justify-content: center;
    // text-align: center;
    align-items: center;
    margin-bottom: 100 px;
}

.diving__loading p {
    padding-top: 30px;
    font-family: 'Nunito';
    font-size: 32px;
    font-weight: 600;
    text-align: center;
    // border: solid 1px red;
    // width: 100%;
    // height: 100%;
    // // align-content: center;
    // // justify-content: center;
    // text-align: justify;
}